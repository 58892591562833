import React, { useCallback, useState } from "react";
import { MainToolbarProps } from "./types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import styles from "./styles";
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import NoteOutlinedIcon from "@material-ui/icons/NoteOutlined";
import { addUserLogs } from "../../system/persons";
import useNotify from "../../hooks/use-notify";
import { useAuthentication } from "../../providers/AuthenticationProvider";

const useStyles = makeStyles(styles);

const MainToolbar: React.FC<MainToolbarProps> = props => {
	const {
		contentStart,
		contentEnd,
		contentRef,

		...toolbarProps
	} = props;

	const classes = useStyles();
	return (
		<AppBar position="relative" color="transparent">
			<Toolbar {...toolbarProps}>
				{!!contentStart && <Box>{contentStart}</Box>}
				<div ref={contentRef} className={classes.contentContainer}>
					{props.children}
				</div>
				{!!contentEnd && <Box ml={2}>{contentEnd}</Box>}
			</Toolbar>
		</AppBar>
	);
};

export const AddMemoDialog = () => {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [note, setNote] = useState("");
	const notify = useNotify();
	const [user] = useAuthentication();

	const addMemo = useCallback(async () => {
		try {
			setLoading(true);
			if (user?.id) {
				await addUserLogs({
					user: user?.id,
					stringNote: note,
					page: window.location.href,
				});
			}
			setOpen(false);
			setNote("");
			notify("User log added succesfully");
			setLoading(false);
			return true;
		} catch (err: any) {
			notify(err);
			setLoading(false);
			return false;
		}
	}, [note, notify, user?.id]);

	return (
		<>
			<Button
				startIcon={<NoteOutlinedIcon />}
				onClick={() => setOpen(true)}
				variant="outlined"
				color="secondary"
				style={{ minWidth: "max-content" }}
			>
				Add Log
			</Button>

			<Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
				<DialogTitle>Add Memo</DialogTitle>
				<DialogContent>
					<form
						onSubmit={e => {
							e.preventDefault();
							addMemo();
						}}
					>
						<Box pb={3}>
							<TextField
								fullWidth
								id="outlined-multiline-static"
								label="Memo"
								multiline
								required
								value={note}
								onChange={e => setNote(e.target.value)}
								minRows={5}
								placeholder="Enter your observations..."
								variant="outlined"
								color="secondary"
							/>
						</Box>
						<Box
							display={"flex"}
							pb={2}
							justifyContent={"flex-end"}
							alignItems={"center"}
							flex={"0 0 auto"}
							gridGap={"1rem"}
						>
							<Button
								color="secondary"
								variant="outlined"
								type="reset"
								onClick={() => setOpen(false)}
							>
								Cancel
							</Button>
							<Button
								disabled={loading}
								type="submit"
								color="secondary"
								variant="contained"
							>
								{loading ? "Loading..." : "Submit"}
							</Button>
						</Box>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default MainToolbar;
