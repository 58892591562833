import { ProductionOrder } from "../types";
import { parseTimeStamp } from "../../../system/utils";

const template = (orders: ProductionOrder[]) => `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Stack order label</title>
    <style>
      body,
      html {
        margin: 0;
        padding: 0;
        font-size: 11pt;
        font-family: sans-serif;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }

      .container {
        position: relative;
        height: 279.4mm;
        width: 203.2mm;
        overflow: hidden;
      }

      header {
        background-color: #f2f2f2;
        padding: 0.1in 0.25in;
        outline: 4px dashed #efefef;
      }

      footer {
        width: 100%;
        background-color: #f2f2f2;
        position: relative;
      }

      .title-main {
        background-color: #f2f2f2;
        font-size: 2em;
        font-weight: normal;
        line-height: 1.675;
        font-style: italic;
        text-align: center;
        margin: 0;
      }

      .square-slot {
        box-sizing: border-box;
        width: 1.25in;
        height: 1.25in;
        vertical-align: bottom;
        display: inline-block;
      }

      .icon-wrapper {
        line-height: 0;
        letter-spacing: 0;
        padding: 0.375in;
      }

      .qr-code-container,
      .placeholder {
        padding: 0;
        width: 1.5in;
        height: 1.5in;
      }
      .qr-code-container img,
      .placeholder img {
        width: 100%;
        height: 100%;
      }

      .placeholder {
        outline: 2px dashed #f5f5f5;
        background-color: #f5f5f5;
      }

      .insert-zone {
        box-sizing: border-box;
        text-align: right;
      }

      .insert-zone .label {
        font-size: 0.85em;
        letter-spacing: 0.08em;
        font-family: monospace;
        font-style: italic;
      }

      .qr-codes-table {
        width: 95%;
        table-layout: fixed;
        margin: auto;
      }

      .qr-codes-table td {
        vertical-align: top;
        padding: 48px 0;
      }

      .qr-codes-table td > p {
        margin: 2.5em 0 1em;
        font-size: 1.2em;
        font-style: italic;
        border-bottom: 1px solid #000000;
      }

      .qr-codes-table td .square-slot {
        margin-bottom: 0.75em;
      }

      .qr-codes-table td .icon-wrapper {
        opacity: 0.2;
      }

      .order-details {
        box-sizing: border-box;
        width: 100%;
        padding: 0.15in 0.25in 0.1in;
        background-color: #f2f2f2;
        border-bottom: 2px solid #e0e0e0;
      }

      .order-details td {
        vertical-align: top;
      }

      td .column-heading span {
        vertical-align: super;
      }
      .column-heading img {
        width: 30px;
      }
      .order-details .description {
        font-weight: normal;
        margin: 0.1em 0 0;
        color: #6b6b6b;
      }

      .order-details .part-number {
        margin: 0 0 0.25em;
        font-size: 1.5em;
        font-weight: normal;
      }

      .order-details .quantity {
        margin: 0;
        font-size: 1.5em;
        font-weight: normal;
        margin-bottom: 0.25em;
        float: right;
        padding: 0 0 1em 1em;
      }

      .order-details .quantity small {
        font-size: 0.65em;
        font-weight: normal;
        font-style: italic;
      }

      .order-details .label {
        margin: 0 0 0 0.25in;
        font-weight: bold;
      }

      .icon-label {
        font-size: 0.85em;
        letter-spacing: 0.08em;
        font-family: monospace;
      }

      .meta-notes {
        /* padding: 1.75em 0.25in; */
        line-height: 1.9375em;
      }

      .meta-notes p {
        margin: 0;
        padding: 0 0.25in;
        font-size: 0.9em;
        color: #6b6b6b;
        border-bottom: 1px solid #e0e0e0;
      }

      .meta-notes p span {
        font-style: italic;
        font-family: monospace;
        letter-spacing: 2pt;
        background-color: #e9e9e9;
        padding: 0.2em 0.5em;
      }

      .branding {
        position: absolute;
        bottom: 0.53in;
        right: 0.25in;
        height: 0.28in;
      }
      .wrapper {
        position: relative;
      }
      .wrapper > .icon-wrapper {
        position: absolute;
        top: 10%;
        right: -0.1in;
      }
    </style>
</head>
<body>
  ${orders
			.map(
				order =>
					`<div class="container">
      <table class="qr-codes-table">
      <tr>
        <td>
          <p class="column-heading">
            <img
              src="https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/document.svg"
            />
            <span
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 70%;
                display: inline-block;
              "
              >${order.product.part_number}</span
            >
          </p>
          <div class="wrapper">
            <div class="square-slot qr-code-container">
              <img
                src="https://api.3hd.us/app/qrcode_gen.create?data=${
																	process.env.REACT_APP_QR_BASENAME || "3hd.us"
																}/${order.docid}"
              />
            </div>
          </div>
          <!-- <br /> -->
          <span class="icon-label">${
											process.env.REACT_APP_QR_BASENAME || "3hd.us"
										}/...${order.docid?.slice(-5)}</span>
        </td>
        <td>
          <p class="column-heading">
            <img
              src="https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/activity_icon.svg"
              class="icon"
            />
            <span>stacked</span>
          </p>
          <div class="wrapper">
            <div class="square-slot qr-code-container">
              <img
                src="https://api.3hd.us/app/qrcode_gen.create?data=${
																	process.env.REACT_APP_QR_BASENAME || "3hd.us"
																}/stacking1"
              />
            </div>
            <div class="square-slot icon-wrapper"></div>
          </div>
          <!-- <br /> -->
          <span class="icon-label">${
											process.env.REACT_APP_QR_BASENAME || "3hd.us"
										}/stacking1</span>
        </td>
        <td>
          <p class="column-heading">
            <img
              src="https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/person.svg"
            />
            <span>by</span>
          </p>
          <div class="insert-zone">
            <div class="wrapper">
              <!-- <div
                style="right: unset; left: -0.4in"
                class="square-slot icon-wrapper"
              >
               
              </div> -->
              <div class="square-slot placeholder"></div>
            </div>
            &bull;
            <span class="label">Place your <b>ID badge</b> here</span>
          </div>
        </td>
      </tr>
    </table>
    <br/>
    <header>
    <h1 class="title-main">
      I am <b>stacking</b> this <b>product</b> to make a bundle
    </h1>
    </header>
      <footer>
        <div class="order-details">
          <h2 class="quantity"><small>x</small>${order.requested_qty}</h2>
          <h1 class="part-number">${order.product.part_number}</h1>
          <p class="description">${order.product.description}</p>
        </div>

        <div class="meta-notes">
          <p>&nbsp;</p>
          <p>Internal Work Order #: <span>${
											order.external_docid || "--"
										}</span></p>
          <p>Created by <span>${order.user.first_name}</span></p>
          <p>on <span>${parseTimeStamp(order.created_at).format(
											"DD MMM, YYYY [at] hh:mma"
										)}</span>.</p>
          <p><span>${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/${
						order.docid
					}</span></p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
        <img src="https://replenish-icons.s3-us-west-1.amazonaws.com/logos/moment_track_logo.svg" class="branding"/>
      </footer>


    </div>`
			)
			.join("")}
</body>
</html>
`;

export default template;
