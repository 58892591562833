import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	IconButton,
	Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { TrainingDocument } from "../system/litmon";
import { visit3hd } from "../../subapp/utils";
import DropDownWrapper from "./DropDownWrapper";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import useNotify from "../hooks/use-notify";
import client from "../system/ordertrack-client";
import DeleteIcon from "@material-ui/icons/Delete";
import { Dispatch, SetStateAction, useState } from "react";
import { parseTimeStamp } from "../system/utils";

const styles = (theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			flexDirection: "column",
			backgroundColor: theme.palette.background.paper,
			// maxWidth: 320,
			overflow: "hidden",
			borderRadius: "8px",
			position: "relative",

			"& .MuiCardContent-root": {
				padding: "1rem",
				paddingBottom: "1rem",
			},
		},
		icons: {
			display: "flex",
			// gap: "1rem",
			justifyContent: "flex-end",
			position: "absolute",
			top: "1rem",
			right: "1rem",
		},
		thumbnail: {
			width: "100%",
			aspectRatio: "16 / 9",
			objectFit: "cover",
			borderRadius: theme.shape.borderRadius,
			"&.MuiSkeleton-root": {
				transform: "unset",
			},
		},
		content: {
			cursor: "pointer",
			"&:hover": {
				textDecoration: "underline",
			},
		},
		title: {
			fontSize: "1rem",
			fontWeight: 500,
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(0.5),
			color: theme.palette.text.primary,
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			overflow: "hidden",
		},
		footer: {
			marginTop: "auto",
			fontSize: "0.75rem",
			color: theme.palette.text.secondary,
			display: "flex",
			justifyContent: "space-between",
		},
	});

const useStyles = makeStyles(styles);

const VideoCard = ({
	item,
	openTrainingModal,
	genenerateLabel,
	setrefetchList,
}: {
	item: TrainingDocument;
	openTrainingModal: (
		trainingData: TrainingDocument,
		type: "edit" | "post"
	) => void;
	genenerateLabel: (title: string, doc_id: string) => void;
	setrefetchList: Dispatch<SetStateAction<number>>;
}) => {
	const classes = useStyles();
	const notify = useNotify();

	const [isLoading, setisLoading] = useState(false);

	const { title, created_at, created_by, doc_id, thumbnail_url } = item;
	return (
		<Card elevation={0} className={classes.root}>
			<div className={classes.icons}>
				<DropDownWrapper
					action={
						<IconButton
							size="small"
							style={{ background: "white" }}
							aria-label="menu"
						>
							<MoreVertIcon />
						</IconButton>
					}
				>
					<Button
						variant="text"
						type="button"
						disabled={isLoading}
						onClick={() => openTrainingModal(item, "edit")}
						aria-label="edit"
					>
						<EditIcon /> Edit
					</Button>
					<Button
						variant="text"
						type="button"
						disabled={isLoading}
						onClick={() => visit3hd(`/${doc_id}`)}
						aria-label="open"
					>
						<OpenInNewIcon /> Open Video
					</Button>
					<Button
						variant="text"
						type="button"
						disabled={isLoading}
						onClick={() => genenerateLabel(title, doc_id)}
						aria-label="generate"
					>
						<PictureAsPdfIcon /> Generate Label
					</Button>
					<Button
						variant="text"
						type="button"
						color="secondary"
						disabled={isLoading}
						onClick={async () => {
							try {
								setisLoading(true);
								await client.delete(`lim/${doc_id}`);
								notify("Training deleted successfully");
								setrefetchList(prev => prev + 1);
							} catch (error) {
								notify(error as Error);
							} finally {
								setisLoading(false);
							}
						}}
						aria-label="delete"
					>
						<DeleteIcon />
						{isLoading ? "Deleting..." : "Delete"}
					</Button>
				</DropDownWrapper>
			</div>
			{/* Thumbnail */}
			<CardMedia
				component="img"
				className={classes.thumbnail}
				src={thumbnail_url || "/images/broken-image.png"}
				alt="Video Thumbnail"
			/>

			{/* Content */}
			<CardContent
				className={classes.content}
				onClick={() => visit3hd(`/${doc_id}`)}
			>
				<Typography variant="h6" className={classes.title}>
					{title}
				</Typography>
				<Box className={classes.footer}>
					<Typography variant="caption">{created_by}</Typography>
					<Typography variant="caption">
						{parseTimeStamp(created_at).fromNow()}
					</Typography>
				</Box>
			</CardContent>
		</Card>
	);
};

export const LoadingVideoCard = () => {
	const classes = useStyles();

	return (
		<Card elevation={0} className={classes.root}>
			{/* Thumbnail */}
			<Skeleton className={classes.thumbnail} />

			{/* Content */}
			<CardContent>
				<Typography variant="h6" className={classes.title}>
					<Skeleton style={{ width: "50%" }} />
				</Typography>
				<Box className={classes.footer}>
					<Skeleton style={{ width: "40%" }} />
					<Skeleton style={{ width: "20%" }} />
				</Box>
			</CardContent>
		</Card>
	);
};
export default VideoCard;
