import { ProductionOrder } from "../types";
import { parseTimeStamp } from "../../../system/utils";

const template = (orders: ProductionOrder[], transfer_qr?: string) => {
	return `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Order Qr template</title>
  <style>
  body,
  html {
    margin: 0;
    padding: 0;
    font-size: 11pt;
    font-family: sans-serif;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .container {
    position: relative;
    height: 4in;
    width: 2in;
    overflow: hidden;
    margin: 1rem auto;
    transform: rotate(-90deg);
  }

  header {
    padding: 0.1in 0.25in;
    outline: 4px dashed #efefef;
  }

  footer {
    width: 100%;
    position: relative;
  }

  .title-main {
    font-size: 2em;
    font-weight: normal;
    line-height: 1.675;
    text-align: center;
    margin: 0;
  }

  .square-slot {
    box-sizing: border-box;
    width: 1.25in;
    height: 1.25in;
    vertical-align: bottom;
    display: inline-block;
  }

  .icon-wrapper {
    line-height: 0;
    letter-spacing: 0;
    padding: 0.375in;
  }

  .qr-code-container,
  .placeholder {
    padding: 0;
    width: 1.9in;
    margin: auto;
    height: 1.9in;
    display: block;
  }
  .qr-code-container img,
  .placeholder img {
    width: 100%;
    height: 100%;
  }

  .placeholder {
    outline: 2px dashed #f5f5f5;
  }

  .insert-zone {
    box-sizing: border-box;
    text-align: right;
  }

  .insert-zone .label {
    font-size: 0.85em;
    letter-spacing: 0.08em;
    font-family: monospace;
  }

  .qr-codes-table {
    width: 100%;
    max-height: 2.5in;
    table-layout: fixed;
    margin: auto;
  }

  .qr-codes-table td {
    vertical-align: top;
    padding: 0;
  }

  .qr-codes-table td > p {
    margin: 2.5em 0 0em;
    font-size: 1.2em;
    border-bottom: 1px solid #000000;
  }

  .qr-codes-table td .square-slot {
    margin-bottom: 0.75em;
  }

  .qr-codes-table td .icon-wrapper {
    opacity: 0.2;
  }

  .order-details {
    box-sizing: border-box;
    width: 100%;
    padding: 0 0.05in;
  }

  .order-details td {
    vertical-align: top;
  }

  td .column-heading span {
    vertical-align: super;
  }
  .column-heading img {
    width: 20px;
    vertical-align: top;
  }
  .column-heading {
    margin: -5px !important;
    padding: 0 4px;
  }
  .order-details .description {
    font-weight: normal;
    margin: 0.1em 0 0;
    font-size: 12px;
  }

  .order-details .part-number {
    margin: 0;
    font-size: 44px;
    font-weight: normal;
    width: max-content;
  }

  .order-details .quantity {
    margin: 0;
    font-size: 1.5em;
    font-weight: normal;
    margin-bottom: 0.25em;
    float: right;
    padding: 0 0 0 1em;
  }

  .order-details .quantity small {
    font-size: 0.65em;
    font-weight: normal;
  }

  .order-details .label {
    margin: 0 0 0 0.25in;
    font-weight: bold;
  }

  .icon-label {
    font-size: 0.85em;
    letter-spacing: 0.08em;
    font-family: monospace;
  }

  .meta-notes {
    padding: 0 0.05in;
  }

  .meta-notes p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 17px;
    display: inline;
  }

  .meta-notes p span {
    font-family: monospace;
    font-size: 12px;
  }

  .branding {
    position: absolute;
    bottom: 0.53in;
    right: 0.25in;
    height: 0.28in;
  }
  .wrapper {
    position: relative;
  }
  .wrapper > .icon-wrapper {
    position: absolute;
    top: 10%;
    right: -0.1in;
  }
</style>
</head>
<body>
  ${orders
			.map(
				order => `
  <div class="container">
  <table class="qr-codes-table">
    <tr>
      <td>
        <div class="wrapper">
          <span style="text-align: center; font-size: 14px; display: block; padding-top: 5px;"
            >${order.product.part_number}</span
          >
          <div class="square-slot qr-code-container">
            <img
              src="https://api.3hd.us/app/qrcode_gen.create?data=3hd.us/${
															order.docid
														}&logo="
            />
          </div>
        </div>
        <p class="column-heading">
          <img
            src="https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/document.svg"
          />
          <span
            style="
                  max-width: 80%;
                  font-size: 10px;
                  display: inline-block;
                  word-break: break-word;
                  margin-left: -1px;
            "
            >${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/${
					order.docid
				}</span
          >
        </p>
      </td>
    </tr>
  </table>
  <footer>
    <div class="order-details">
      <h1 style="font-size: ${
							order.product.part_number.length > 7
								? Math.max(18, 35 * (7 / order.product.part_number.length))
								: 35
						}px; margin-top: 4px" class="part-number">${
					order.product.part_number
				}</h1>
      <p class="description">${order.product.description}</p>
    </div>

    <div class="meta-notes">
      <p><b>Qty:</b> <span>${order.requested_qty}</span></p>
      <p><b>WO #:</b> <span>${order.external_docid}</span></p>
      <p style="display: block;">
      <b>Created by</b> <span>${
							order.user.first_name
						}</span> <b>on</b> <span>${parseTimeStamp(order.created_at).format(
					"DD MMM, YYYY [at] hh:mma"
				)}</span>
       
      </p>
    </div>
  </footer>
</div>
<div class="container" style="position: relative">
<p style="text-align: center; font-size: 24px; margin: 16px auto">
Transfer to tools
</p>
<div class="qr-code-container">
<img
    src="https://api.3hd.us/app/qrcode_gen.create?data=${transfer_qr}"
/>
</div>
</div>
  `
			)
			.join("")}
</body>
</html>
`;
};

export default template;
