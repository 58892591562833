import React, { useRef } from "react";
import { Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Layout, {
	Content,
	Header,
	Footer,
	Slot,
} from "../core/components/Layout";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ClearIcon from "@material-ui/icons/Clear";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import styles from "./styles";
import { BinFamily } from "../core/system/bin-families";
import useBinFamily from "../core/hooks/use-bin-families";
import useSearchFilter from "../core/hooks/use-search-filter";
import { createOrderPlate } from "../core/system/order-plates";
import EmbeddedContentDialog, {
	EmbeddedContentDialogApi,
} from "../core/components/EmbeddedContentDialog";
import { AddMemoDialog } from "../core/components/MainToolbar/MainToolbar";

const useStyles = makeStyles(styles);

const OrderPlates: React.FC = () => {
	const classes = useStyles();

	const [searchTerm, setSearchTerm] = useState("");
	const [binFamilies, loading] = useBinFamily();
	const searchedBinFamilies = useSearchFilter(
		binFamilies,
		"product",
		searchTerm
	);

	const [selection, setSelection] = useState<BinFamily[]>([]);
	const isSmallScreen = useMediaQuery(
		(theme: Theme) => theme.breakpoints.down("sm"),
		{ noSsr: true }
	);

	function handleCardSelectionChange(selected: boolean, binFamily: BinFamily) {
		setSelection(selection => {
			if (selected) return [...selection, binFamily];
			else return selection.filter(family => family.id !== binFamily.id);
		});
	}

	const pdfDialog = useRef<EmbeddedContentDialogApi>(null);

	const handleGenerateOrderPlates = useCallback(async () => {
		const plate = createOrderPlate({ binFamilies: selection });
		const pdfUrl: string = await plate.getPdf();

		return pdfUrl;
	}, [selection]);

	return (
		<>
			<Slot name="main-toolbar">
				<Grid container alignItems="center" spacing={2}>
					<Grid item>
						{loading && <CircularProgress size={24} />}
						{!loading && <Typography variant="h6">Create order plate</Typography>}
					</Grid>
					<Grid item xs />
					<Grid item>
						<AddMemoDialog />
					</Grid>
				</Grid>
			</Slot>

			<Grid container style={{ height: "calc(100% - 20px)" }}>
				<Grid
					item
					component={Box}
					height={!isSmallScreen ? "100%" : "auto"}
					xs={12}
					md={6}
				>
					<Layout>
						<Header py={2}>
							<Container maxWidth={false}>
								<Grid
									container
									justify="space-between"
									alignItems="flex-end"
									spacing={1}
								>
									<Grid item>
										<Typography variant="h6">Products</Typography>
										<Typography variant="caption" color="textSecondary">
											Select products to list on the order plate.
										</Typography>
									</Grid>
									<Grid item>
										<TextField
											placeholder="Search product name"
											value={searchTerm}
											onChange={ev => setSearchTerm(ev.target.value)}
											variant="standard"
											color="secondary"
											size="small"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<SearchIcon color="secondary" />
													</InputAdornment>
												),
											}}
										/>
									</Grid>
								</Grid>
							</Container>
						</Header>
						<Content py={2}>
							<Container maxWidth={false}>
								<List component={Paper}>
									{loading && (
										<Box p={2}>
											<CircularProgress size={24} />
										</Box>
									)}
									{searchedBinFamilies.map(binFamily => (
										<ListItem
											key={"" + binFamily.id}
											className={classes.listItem}
											divider
											selected={selection.indexOf(binFamily) > -1}
										>
											<ListItemText
												primary={binFamily.product.name}
												// primary={binFamily.product.description}
												secondary={binFamily.product.part_number}
												primaryTypographyProps={{ variant: "body1" }}
												style={{ maxWidth: "calc(100% - 70px)" }}
											/>
											<ListItemSecondaryAction>
												{selection.indexOf(binFamily) < 0 ? (
													<Button
														onClick={() => handleCardSelectionChange(true, binFamily)}
														variant="outlined"
														color="primary"
														size="small"
														startIcon={<AddIcon />}
													>
														Select
													</Button>
												) : (
													<Button disabled>Selected</Button>
												)}
											</ListItemSecondaryAction>
										</ListItem>
									))}
								</List>
							</Container>
						</Content>
					</Layout>
				</Grid>
				<Grid
					item
					component={Box}
					height={!isSmallScreen ? "100%" : "auto"}
					xs={12}
					md={6}
				>
					<Layout>
						<Header py={2} pt={4}>
							<Container maxWidth={false}>
								<Grid
									container
									justify="space-between"
									alignItems="flex-end"
									spacing={1}
								>
									<Grid item>
										<Typography variant="h6">Selected products</Typography>
										<Typography variant="caption" color="textSecondary">
											Products selected to list on the order plate.
										</Typography>
									</Grid>
									{selection.length > 0 && (
										<Grid item>
											<Button
												onClick={() => setSelection([])}
												variant="outlined"
												startIcon={<ClearIcon />}
											>
												Clear selection
											</Button>
										</Grid>
									)}
								</Grid>
							</Container>
						</Header>
						<Content py={2}>
							<Container maxWidth={false}>
								<List component={Paper}>
									{selection.map(binFamily => (
										<ListItem key={"" + binFamily.id} divider>
											<ListItemText
												primary={binFamily.product.name}
												// primary={binFamily.product.description}
												secondary={binFamily.product.part_number}
												primaryTypographyProps={{ variant: "body1" }}
											/>
											<ListItemSecondaryAction>
												<IconButton
													onClick={() => handleCardSelectionChange(false, binFamily)}
													color="primary"
													edge="end"
												>
													<RemoveIcon color="error" />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									))}
									{selection.length === 0 && (
										<ListItem>
											<ListItemText primary="No items selected" />
										</ListItem>
									)}
								</List>
							</Container>
						</Content>
						<Footer py={2}>
							<Container maxWidth={false}>
								<Grid container spacing={2}>
									<Grid item xs={12} md={6}>
										<Button
											onClick={() => {
												pdfDialog.current?.openDialog(() => handleGenerateOrderPlates());
												console.log();
											}}
											disabled={selection.length === 0}
											variant="contained"
											size="large"
											color="primary"
											fullWidth
										>
											Generate order plates
										</Button>
										<EmbeddedContentDialog ref={pdfDialog} title="Order plate" />
									</Grid>
								</Grid>
							</Container>
						</Footer>
					</Layout>
				</Grid>
			</Grid>
		</>
	);
};

export default OrderPlates;
