import { StackType } from "./types";
import { parseTimeStamp } from "../../system/utils";

const template = (stacks: StackType[], edited?: boolean) => {
	return `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Order Qr template</title>
  <style>
      body,
      html {
        margin: 0;
        padding: 0;
        font-size: 10px;
        font-family: "Noto Sans SC", Arial, sans-serif;
      }

      .container {
        width: 6in;
        margin: 0 auto;
        height: 4in;
        position: relative;
        border: 1px solid #bdbdbd;
        margin-top: 10px;
        white-space: nowrap;
        overflow: hidden;
      }
      .label-root p {
        margin: 0;
        word-break: break-word;
      }
      .label-root {
        padding: 0.05in 0.05in 0.1in;
        max-width: 97.5%;
        line-height: normal;
        background: #ffffff;
      }
      .label-root .general-wrapper > div {
        display: inline-block;
      }

      .label-root .qr-code-wrapper {
        width: 2.3in;
        height: 2.3in;
        line-height: 0;
      }
      .label-root .details-wrapper {
        max-width: 2.2in;
      }
      .qr-code-wrapper .qr-code {
        width: 100%;
        height: 100%;
      }

      .title {
        font-weight: 700;
        display: block;
        width: max-content;
        font-size: 100px;
        margin: 0 auto 0.035in !important;
        text-align: center;
      }

      .label-root .subtitle {
        font-size: 54px;
      }
      .label-root .body-text {
        font-size: 15px;
        margin-top: 5px;
      }
    </style>
</head>
<body>
  ${stacks
			.map(
				stack => `
      <div class="container">
      <!-- BEGIN: Label -->
      <div class="label-root">
        <p id="title" class="title" style="font-size: ${
									`${stack.part_number}`.length > 7
										? Math.max(40, 100 * (7 / `${stack.part_number}`.length))
										: 100
								}px">${stack.part_number}</p>
        <table>
        <tbody>        
        <tr class="general-wrapper">
          <td>
          <div>
          <div style="margin-bottom: -10px; min-width: 120px">
            <p style="display: inline; font-size: 22px">${
													edited
														? "<span style='font-size: 16px;'>User Defined</span> <br/>"
														: ""
												} Quantity:</p>
          </div>
          <p style="font-size: ${
											String(stack.item_count).length > 2
												? Math.max(40, 100 * (2 / String(stack.item_count).length))
												: 100
										}px" class="subtitle">${stack.item_count}</p>
          </div>
          </td>
          <td >
          <div style="text-align: center; padding-left: 0.05in;" class="details-wrapper">
          <p class="subtitle">${parseTimeStamp(stack.created_at).format(
											"MMM DD"
										)}</p>
          <p style="font-size: 48px" class="subtitle">${parseTimeStamp(
											stack.created_at
										).format("hh:mma")}</p>
          <p style="
            overflow-wrap: break-word;
            display: block;
            white-space: normal;
          " class="body-text">${stack?.description || "--"}</p>
          <p
            style="
              font-size: 12px;
              white-space: normal;
              word-break: break-word;
            "
            class="body-text"
          >
          ${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/${stack.stack_id}
          </p>
          </div>
          </td>
          <td>
          <div class="qr-code-wrapper">
          <img
          src="https://api.3hd.us/app/qrcode_gen.create?data=3hd.us/${
											stack.stack_id
										}&logo=momenttrack"
          class="qr-code"
          />
          </div>
          </td>
        </tr>
        </tbody>
        </table>
      </div>
      <!-- END: Label -->
    </div>  
    <div class="container" style="position: relative">
      <p style="text-align: center; font-size: 32px; margin: 16px auto">
        Transfer to tools
      </p>
      <img
        style="width: 3in; height: 3in; margin: auto; display: block"
        src="https://api.3hd.us/app/qrcode_gen.create?data=%09${parseTimeStamp(
									stack.created_at
								).format("MM/DD/YYYY")}%09%09%09${stack.item_count}"
        class="qr-code"
      />
    </div>
    `
			)
			.join("")}
</body>
</html>
`;
};

/* 
 <img
        style="width: 3in; height: 3in; margin: auto; display: block"
        src="https://api.3hd.us/app/qrcode_gen.create?data=3hd.us/%09${moment
          .utc(stack.created_at)
          .local()
          .format("MMM DD, YYYY hh:mma")}%09%09%09${stack.item_count}"
        class="qr-code"
      />
*/
export default template;
