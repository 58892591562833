import React, { useState, useRef, useMemo } from "react";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Icon from "@material-ui/core/Icon";
import Layout, { Header, Content, Slot } from "../../core/components/Layout";
import SearchField from "../../core/components/SearchField";
import DataGrid, { ColumnDef, RowData } from "../../core/components/DataGrid";
import PlaceInputDialog, { PlaceInputDialogApi } from "../PlaceInputDialog";
import EmbeddedContentDialog, {
	EmbeddedContentDialogApi,
} from "../../core/components/EmbeddedContentDialog";
import PlaceActions from "../PlaceActions";
import { Place, generateActivitySheets } from "../../core/system/places";
import usePlaces from "../../core/hooks/use-places";
import OptionsDialog, {
	OptionsDialogApi,
} from "../../core/components/OptionsDialog";
import { useLocation } from "react-router-dom";
import { visit3hd } from "../../subapp/utils";
import { AddMemoDialog } from "../../core/components/MainToolbar/MainToolbar";

export type CellType = Place & { isEditOpen: boolean };
const columns: ColumnDef[] = [
	{
		field: "name",
		headerName: "NAME",
		sortable: true,
		padding: "checkbox",
		renderCell: (data: CellType) => (
			<Link
				onClick={() => visit3hd(`/${data.beacon_id}`)}
				target="_BLANK"
				variant="body1"
			>
				{data.name}
			</Link>
		),
	},
	{
		field: "beacon_id",
		headerName: "QR code",
		renderCell: (data: CellType) => (
			<Link
				color="textPrimary"
				onClick={() => visit3hd(`/${data.beacon_id}`)}
				target="_BLANK"
			>
				<Typography component="span">
					<small>{process.env.REACT_APP_QR_BASENAME || "3hd.us"}/...</small>
					{data.beacon_id.slice(-5)}
				</Typography>
			</Link>
		),
	},
	{
		field: "actions",
		headerName: " ",
		padding: "checkbox",
		renderCell: (data: CellType) => <PlaceActions place={data} />,
	},
];

const ViewAllPlaces: React.FC = () => {
	const activitySheetsDialog = useRef<EmbeddedContentDialogApi>(null);
	const [places, loading] = usePlaces();
	const [selection, setSelection] = useState<Place[]>([]);
	const placeInputDialog = useRef<PlaceInputDialogApi>(null);
	const placeOptionsDialog = useRef<OptionsDialogApi>(null);
	const [searchQuery, setSearchQuery] = useState("");

	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const editId = urlParams.get("edit");

	const filteredPlaces = useMemo(() => {
		return places
			.filter(
				b => b.name.toLowerCase().search(searchQuery.trim().toLowerCase()) > -1
			)
			.map(el => ({ ...el, isEditOpen: el.id.toString() === editId }));
	}, [places, searchQuery, editId]);

	const handleSelection = (selection: RowData[]) => {
		setSelection(selection as Place[]);
	};

	const handleButtonClick = () => {
		placeOptionsDialog.current?.openDialog();
	};

	const handleSubmit = (option: string) => {
		activitySheetsDialog.current?.openDialog(() =>
			generateActivitySheets(selection, option)
		);
	};

	return (
		<>
			<Slot name="main-toolbar">
				<Grid
					container
					alignItems="center"
					justifyContent="space-between"
					spacing={2}
				>
					<Grid item>
						{loading && <CircularProgress size={24} />}
						{!loading && <Typography variant="h6">Places</Typography>}
					</Grid>
					<Grid item style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
						<AddMemoDialog />
						<Button
							color="secondary"
							variant="contained"
							disabled={loading}
							style={{ marginLeft: "auto", display: "flex" }}
							fullWidth
							onClick={() => placeInputDialog.current?.openDialog()}
						>
							<Icon>add</Icon> Add Place
						</Button>
					</Grid>
				</Grid>
			</Slot>
			<Grid
				style={{ padding: "0 1rem 1rem" }}
				justifyContent="space-between"
				container
				alignItems="center"
			>
				<Grid item xs={12} sm={5} md={4}>
					<SearchField
						value={searchQuery}
						onChange={ev => setSearchQuery(ev.target.value)}
						disabled={loading}
					/>
				</Grid>
			</Grid>

			<Layout>
				<Header>
					<Container maxWidth={false}>
						<Paper>
							<Box px={2} py={1}>
								<Grid container alignItems="center" spacing={2}>
									<Grid item>
										<Hidden xsDown>
											<Button
												color="secondary"
												startIcon={<Icon>print</Icon>}
												onClick={handleButtonClick}
												disabled={loading || selection.length === 0}
											>
												Get labels
											</Button>
										</Hidden>
										<Hidden smUp>
											<IconButton
												edge="start"
												color="secondary"
												onClick={handleButtonClick}
												disabled={loading || selection.length === 0}
											>
												<Icon>print</Icon>
											</IconButton>
										</Hidden>
									</Grid>
									<Grid item>
										<Typography>
											<b>{selection.length || "No"}</b> rows selected.
										</Typography>
									</Grid>
								</Grid>
							</Box>
						</Paper>
					</Container>
				</Header>
				<Content>
					<Container maxWidth={false} style={{ height: "100%" }}>
						<DataGrid
							columns={columns}
							rows={filteredPlaces}
							checkboxSelection
							onSelectionChange={handleSelection}
							disableSelectionOnClick
						/>
					</Container>
				</Content>
			</Layout>

			<PlaceInputDialog ref={placeInputDialog} title="New place" />
			<OptionsDialog
				ref={placeOptionsDialog}
				title="Choose option:"
				onSubmit={handleSubmit}
				options={[
					{ label: "Move one auto submit", value: "move-one" },
					{ label: "Move many manual submit", value: "move-many" },
					{ label: "Location Only", value: "location-only" },
				]}
			/>

			<EmbeddedContentDialog ref={activitySheetsDialog} title="New place" />
		</>
	);
};

export default ViewAllPlaces;
