import React, { useEffect, useMemo, useState } from "react";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { useContext } from "react";
import { orderContext } from "../../../../../core/providers/PlaceReportProvider/context";
import StackedBarChart from "../../../../../core/components/PieChart/StackedBarChart";
import { Box } from "@material-ui/core";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import { parseTimeStamp } from "../../../../../core/system/utils";

const useStyles = makeStyles(styles);

const OrderStats: React.FC = () => {
	const orderReport = useContext(orderContext);
	const classes = useStyles();

	const requestedQty = orderReport?.requested_qty || 0;

	const distributionMap = useMemo(() => {
		if (!orderReport) return {};

		return orderReport.lineitems_totals
			.filter(el => el.total_items >= 1)
			.reduce(
				(map, location) => {
					const statusItem = orderReport?.lineitems?.find(
						el => el.location_id === location.location_id
					);
					return {
						...map,
						[location.name]: {
							total: location.total_items,
							icon: statusItem?.location?.logo_url || "",
							id: statusItem?.location?.beacon_id || "",
						},
					};
				},
				{} as {
					[key: string]: { total: number; icon: string; id: string | number };
				}
			);
	}, [orderReport]);

	const [quantityPieSeries, setquantityPieSeries] = useState<{
		series: any[];
		total: number;
	}>();

	useEffect(() => {
		let data;
		if (requestedQty === 0) data = {};
		let totalAssigned = Object.keys(distributionMap).reduce((cum, cur) => {
			return cum + distributionMap[cur].total;
		}, 0);
		data = {
			series: [
				{
					name: "Not started",
					data: [requestedQty - totalAssigned],
					color: "#fff",
				},
				...Object.keys(distributionMap).map((stationName, i) => {
					return {
						name: stationName,
						data: [distributionMap[stationName].total],
					} as any;
				}),
			],
			total: totalAssigned,
		};
		setquantityPieSeries(data);
	}, [distributionMap, requestedQty]);

	return (
		<div>
			{orderReport && (
				<>
					<Chip
						className={classes.chip}
						size="small"
						icon={<Icon fontSize="small">production_quantity_limits</Icon>}
						label={`${quantityPieSeries?.total} out of ${orderReport.requested_qty}`}
					/>
					<Chip
						className={classes.chip}
						size="small"
						icon={<CalendarTodayIcon />}
						label={parseTimeStamp(orderReport.created_at).format("l")}
					/>
					<Chip
						className={classes.chip}
						size="small"
						icon={<Icon fontSize="small">engineering</Icon>}
						label={orderReport.user?.first_name}
					/>
					<Chip
						className={classes.chip}
						size="small"
						icon={<ConfirmationNumberIcon />}
						label={orderReport.product.part_number}
					/>

					<Box p={2} mt={2}>
						<StackedBarChart
							options={{
								xAxis: {
									categories: ["Quantity"],
									visible: false,
								},
								yAxis: {
									ceiling: requestedQty,
								},
							}}
							series={quantityPieSeries?.series}
							// getSvg={handleGetSvg}
						/>
					</Box>
				</>
			)}
		</div>
	);
};

export default OrderStats;
