import React, { useMemo, useRef, useState } from "react";
import VendorDialog, { VendorDialogRef } from "./VendorDialog";
import SearchField from "../core/components/SearchField";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import VendorCard from "./VendorCard";
import Typography from "@material-ui/core/Typography";
import useSearchFilter from "../core/hooks/use-search-filter";
import useVendors from "../core/hooks/use-vendors";
import { Vendor, VendorInputData } from "../core/system/vendors";
import useNotify from "../core/hooks/use-notify";
import { Slot } from "../core/components/Layout";
import {
	Button,
	CircularProgress,
	Icon,
	MenuItem,
	TextField,
} from "@material-ui/core";
import { AddMemoDialog } from "../core/components/MainToolbar/MainToolbar";

const sortHandlersMap = {
	"a-z": (a: Vendor, b: Vendor) =>
		a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
	"z-a": (a: Vendor, b: Vendor) =>
		a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1,
	newest: (a: Vendor, b: Vendor) =>
		new Date(a.created_at).getTime() > new Date(b.created_at).getTime() ? -1 : 1,
	oldest: (a: Vendor, b: Vendor) =>
		new Date(a.created_at).getTime() > new Date(b.created_at).getTime() ? 1 : -1,
};
const VendorsPage: React.FC = function () {
	const [vendors, loading, , vendorsService] = useVendors();
	const [searchTerm, setSearchTerm] = useState("");
	const [sortBy, setSortBy] = useState<keyof typeof sortHandlersMap>("a-z");

	const sortedVendors = useMemo(
		() => [...vendors.sort(sortHandlersMap[sortBy])],
		[vendors, sortBy]
	);

	const searchedVendors = useSearchFilter(
		sortedVendors,
		"name,erp_number",
		searchTerm
	);
	const vendorDialog = useRef<VendorDialogRef>(null);
	const notify = useNotify();

	async function handleVendorCreate(data: VendorInputData) {
		await vendorsService.create(data);
		notify("Vendor created.");
	}

	return (
		<>
			<Slot name="main-toolbar">
				<Grid
					container
					alignItems="center"
					justifyContent="space-between"
					spacing={2}
				>
					<Grid item>
						{loading && <CircularProgress size={24} />}
						{!loading && <Typography variant="h6">Vendors</Typography>}
					</Grid>
					<Grid item style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
						<AddMemoDialog />
						<Button
							color="secondary"
							variant="contained"
							onClick={() => vendorDialog.current?.openDialog(true)}
							disabled={loading}
							style={{ marginLeft: "auto", display: "flex" }}
							fullWidth
						>
							<Icon>add</Icon> Add Vendor
						</Button>
					</Grid>
				</Grid>
			</Slot>

			<Grid
				style={{ padding: "0 1rem 1rem", gap: "1.2rem" }}
				justifyContent="space-between"
				container
				alignItems="center"
			>
				<Grid item xs={12} sm={5} md={4}>
					<SearchField
						value={searchTerm}
						onChange={ev => setSearchTerm(ev.target.value)}
						disabled={loading}
					/>
				</Grid>
				<Grid item xs sm={4} md={3} lg={2}>
					<TextField
						label="SORT"
						value={sortBy}
						onChange={ev => setSortBy(ev.target.value as typeof sortBy)}
						select
						variant="outlined"
						size="small"
						fullWidth
					>
						<MenuItem value="a-z">A-Z</MenuItem>
						<MenuItem value="z-a">Z-A</MenuItem>
						<MenuItem value="newest">Newest first</MenuItem>
						<MenuItem value="oldest">Oldest first</MenuItem>
					</TextField>
				</Grid>
			</Grid>

			<Container maxWidth={false}>
				<Box py={2}>
					<Grid container spacing={1}>
						{searchedVendors.map((vendor: Vendor) => (
							<Grid key={"" + vendor.id} item xs={12} sm={4} md={3} xl={2}>
								<VendorCard vendor={vendor} />
							</Grid>
						))}
					</Grid>
				</Box>

				<VendorDialog
					ref={vendorDialog}
					primaryActionText="Create"
					secondaryActionText="Cancel"
					primaryAction={handleVendorCreate}
				/>
			</Container>
		</>
	);
};

export default VendorsPage;
