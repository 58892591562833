import { useMemo } from "react";
import { PlaceActivityLog } from "./types";
import usePlaceReport from "./usePlaceReport";
import { parseTimeStamp } from "../../system/utils";

const useFirstTimeRightReport = (
	dateRange = { from: new Date(0), to: new Date() }
) => {
	const report = usePlaceReport();

	const reportData: [number, number] | null = useMemo(() => {
		if (!report) return null;

		const lpLogsMap = (report.logs || []).reduce((map, log) => {
			const arrivedAt = parseTimeStamp(log.arrived_at).toDate();
			if (arrivedAt >= dateRange?.from && arrivedAt <= dateRange?.to) {
				const currSet = map[log.license_plate.lp_id] || [];
				currSet.push(log);
				map[log.license_plate.lp_id] = currSet;
			}

			return map;
		}, {} as Record<string, PlaceActivityLog[]>);

		let totalRight = 0;
		let totalRework = 0;

		for (let key in lpLogsMap) {
			if (lpLogsMap[key].length === 1 && !!lpLogsMap[key][0].left_at) totalRight++;
			else if (lpLogsMap[key].length > 1) totalRework++;
		}

		return [totalRight, totalRework];
	}, [dateRange, report]);

	return reportData; // [<number of lps right first time>, <reworked>]
};

export default useFirstTimeRightReport;
