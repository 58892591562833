import React, { useState } from "react";
import { PersonActionsProps } from "./types";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import PersonEditDialog from "../PersonEditDialog";
// import RoleEditDialog from "../RoleEditDialog";
import usePersons from "../../core/providers/PersonsProvider/use-persons";
import useConfirmations from "../../core/hooks/use-confirmations";
import { Tooltip } from "@material-ui/core";
// import GetAppIcon from "@material-ui/icons/GetApp";

const PersonActions: React.FC<PersonActionsProps> = ({ person }) => {
	const [, loading, personActions] = usePersons();
	// const [roleEditDialogOpen, setRoleEditDialogOpen] = useState(false);
	const [openPersonEditDialog, setOpenPersonEditDialog] = useState(false);
	const confirm = useConfirmations();

	const handleDelete = async () => {
		if (await confirm(`Are you sure to delete person "${person.email}"?`))
			personActions.remove(person);
	};

	return (
		<Grid
			container
			style={{ width: "max-content" }}
			className="person-actions"
			alignItems="center"
		>
			<Grid item>
				<Tooltip title="Delete Person" placement="right">
					<IconButton onClick={handleDelete} disabled={loading} size="small">
						<Icon color="error">delete</Icon>
					</IconButton>
				</Tooltip>
			</Grid>
			{/* Disable feature until backend part is sorted out */}
			{/* <Grid item>
        <Tooltip title="Edit Role" placement="right">
          <IconButton
            onClick={() => setRoleEditDialogOpen(true)}
            disabled={loading}
            size="small"
          >
            <Icon>supervisor_account</Icon>
          </IconButton>
        </Tooltip>

        <RoleEditDialog
          person={person}
          open={roleEditDialogOpen}
          onClose={() => setRoleEditDialogOpen(false)}
          maxWidth="xs"
          fullWidth
        />
      </Grid> */}
			<Grid item>
				<Tooltip title="Edit Person Details" placement="right">
					<IconButton
						disabled={loading}
						onClick={() => setOpenPersonEditDialog(true)}
						size="small"
					>
						<Icon>edit</Icon>
					</IconButton>
				</Tooltip>

				<PersonEditDialog
					open={openPersonEditDialog}
					onClose={() => setOpenPersonEditDialog(false)}
					person={person}
				/>
			</Grid>

			{/* <Grid item>
				<Tooltip title="Download User Logs" placement="right">
					<IconButton disabled={loading} size="small">
						<GetAppIcon />
					</IconButton>
				</Tooltip>
			</Grid> */}
		</Grid>
	);
};

export default PersonActions;
