import React, { useState, useRef, useMemo } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Icon from "@material-ui/core/Icon";
import useProducts from "../../core/hooks/use-products";
import { Slot } from "../../core/components/Layout";
import SearchField from "../../core/components/SearchField";
import ProductInputDialog, {
	ProductInputDialogApi,
} from "../ProductInputDialog";
import ProductCard from "../ProductCard";
import { Product } from "../../core/system/products";
import { Button } from "@material-ui/core";
import { AddMemoDialog } from "../../core/components/MainToolbar/MainToolbar";

const sortHandlersMap = {
	"a-z": (a: Product, b: Product) =>
		a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
	"z-a": (a: Product, b: Product) =>
		a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1,
	newest: (a: Product, b: Product) =>
		new Date(a.created_at).getTime() > new Date(b.created_at).getTime() ? -1 : 1,
	oldest: (a: Product, b: Product) =>
		new Date(a.created_at).getTime() > new Date(b.created_at).getTime() ? 1 : -1,
	"pn-a-z": (a: Product, b: Product) =>
		a.part_number.toLowerCase() > b.part_number.toLowerCase() ? 1 : -1,
	"pn-z-a": (a: Product, b: Product) =>
		a.part_number.toLowerCase() > b.part_number.toLowerCase() ? -1 : 1,
};

const ViewAllProducts: React.FC = () => {
	const [products, loadingProducts] = useProducts();
	const productInputDialog = useRef<ProductInputDialogApi>(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [sortBy, setSortBy] = useState<keyof typeof sortHandlersMap>("a-z");

	const sortedProducts = useMemo(
		() => [...products.sort(sortHandlersMap[sortBy])],
		[products, sortBy]
	);
	const searchedProducts = useMemo(
		() =>
			sortedProducts.filter(
				b =>
					`${b.name.toLowerCase()}_${b.part_number.toLowerCase()}`.search(
						searchQuery.trim().toLowerCase()
					) > -1
			),
		[sortedProducts, searchQuery]
	);

	return (
		<>
			<Slot name="main-toolbar">
				<Grid
					container
					alignItems="center"
					justifyContent="space-between"
					spacing={2}
				>
					<Grid item>
						{loadingProducts && <CircularProgress size={24} />}
						{!loadingProducts && <Typography variant="h6">Products</Typography>}
					</Grid>
					<Grid item style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
						<AddMemoDialog />
						<Button
							color="secondary"
							variant="contained"
							onClick={() => productInputDialog.current?.openDialog()}
							disabled={loadingProducts}
							style={{ marginLeft: "auto", display: "flex" }}
							fullWidth
						>
							<Icon>add</Icon> Add Product
						</Button>
					</Grid>
				</Grid>
			</Slot>

			<Grid
				style={{ padding: "0 1rem 1rem", gap: "1.2rem" }}
				justifyContent="space-between"
				container
				alignItems="center"
			>
				<Grid item xs={12} sm={5} md={4}>
					<SearchField
						value={searchQuery}
						onChange={ev => setSearchQuery(ev.target.value)}
						disabled={loadingProducts}
					/>
				</Grid>
				<Grid item xs sm={4} md={3} lg={2}>
					<TextField
						label="SORT"
						value={sortBy}
						onChange={ev => setSortBy(ev.target.value as typeof sortBy)}
						select
						variant="outlined"
						size="small"
						fullWidth
					>
						<MenuItem value="a-z">A-Z</MenuItem>
						<MenuItem value="z-a">Z-A</MenuItem>
						<MenuItem value="pn-a-z">Part number A-Z</MenuItem>
						<MenuItem value="pn-z-a">Part number Z-A</MenuItem>
						<MenuItem value="newest">Newest first</MenuItem>
						<MenuItem value="oldest">Oldest first</MenuItem>
					</TextField>
				</Grid>
			</Grid>

			<Container maxWidth={false}>
				<Box py={2}>
					<Grid container spacing={2}>
						{searchedProducts.map(product => (
							<Grid key={"" + product.id} item xs={12} sm={4} lg={3}>
								<ProductCard product={product} />
							</Grid>
						))}
					</Grid>
				</Box>
			</Container>

			<ProductInputDialog ref={productInputDialog} title="New product" />
		</>
	);
};

export default ViewAllProducts;
