import React, { useState } from "react";
import useNotify from "../core/hooks/use-notify";
import Layout, { Content, Slot } from "../core/components/Layout";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataGrid, { ColumnDef } from "../core/components/DataGrid";
import {
	DesignApiResponseType,
	DesignDataType,
	downloadDesignDatatoCsv,
	getDesignData,
} from "../core/system/design-imaging";
import {
	Button,
	Checkbox,
	Chip,
	Icon,
	InputAdornment,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";
import { capitalize, removeEmpty } from "../core/system/utils";
import { Autocomplete, Pagination } from "@material-ui/lab";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const formatSearchParams = (params: Record<string, unknown>): string => {
	const contains: string[] = [];
	const isExact: string[] = [];
	const processedFields = new Set<string>();

	// First, process all exact matches to ensure priority
	Object.entries(params).forEach(([key, value]) => {
		if (key.endsWith("_match_type") && value === "exact") {
			const fieldKey = key.replace("_match_type", "");
			if (params[fieldKey] !== undefined && params[fieldKey] !== null) {
				isExact.push(`${fieldKey}:${params[fieldKey]}`);
				processedFields.add(fieldKey); // Mark as processed
			}
		}
	});

	// Now, process remaining fields that were not marked as exact
	Object.entries(params).forEach(([key, value]) => {
		if (!key.endsWith("_match_type") && !processedFields.has(key)) {
			contains.push(`${key}:${value}`);
		}
	});

	const queryParams = [];
	if (contains.length > 0) {
		queryParams.push(`contains=${contains.join(",")}`);
	}
	if (isExact.length > 0) {
		queryParams.push(`is=${isExact.join(",")}`);
	}

	return queryParams.length > 0 ? `${queryParams.join("&")}` : "";
};

const DesignTable: React.FC = () => {
	const notify = useNotify();
	const searchByFields = [
		"license_plate_id",
		"masking_batch",
		"pur_batch",
		"pvc_batch",
		"sales_order",
		"job_name",
		"item_sales_id",
		"item_id",
		"item_number",
		"color_code",
		"pur_qr",
		"pvc_qr",
		"masking_qr",
		"transaction_status",
	];

	const [loading, setLoading] = useState(false);
	const [searchState, setSearchState] = useState<{
		[key: string]: string;
	}>({});
	const [selectedFields, setSelectedFields] = useState<string[]>(searchByFields);
	const [data, setData] = useState<DesignApiResponseType | null>(null);
	const [page, setPage] = useState(1);

	const csvData = data?.data?.map(el => ({
		"License Plate ID": el.license_plate_id,
		"Masking Batch": el.masking_batch,
		"Pur Batch": el.pur_batch,
		"Pvc Batch": el.pvc_batch,
		"Sales Order": el.sales_order,
		"Job Name": el.job_name,
		"Item Sales Id": el.item_sales_id,
		"Item Id": el.item_id,
		"Item Number": el.item_number,
		"Color Code": el.color_code,
		"Pur QR": el.pur_qr,
		"Pvc QR": el.pvc_qr,
		"Masking QR": el.masking_qr,
		"Transaction Status": el.transaction_status,
	}));

	const columns: ColumnDef[] = [
		{
			field: "license_plate",
			headerName: "License Plate",
			sortable: true,
			valueGetter: (data: DesignDataType) => data.license_plate_id,
		},
		{
			field: "masking_batch",
			headerName: "Masking Batch",
			sortable: true,
			valueGetter: (data: DesignDataType) => data?.masking_batch,
		},
		{
			field: "pur_batch",
			headerName: "PUR Batch",
			valueGetter: (data: DesignDataType) => data?.pur_batch,
		},
		{
			field: "pvc_batch",
			headerName: "PVC Batch",
			sortable: true,
			valueGetter: (data: DesignDataType) => data?.pvc_batch,
		},
		{
			field: "sales_order",
			headerName: "Sales Order",
			valueGetter: (data: DesignDataType) => data?.sales_order,
		},
		{
			field: "job_name",
			headerName: "Job Name",
			sortable: true,
			valueGetter: (data: DesignDataType) => data?.job_name || "--",
		},
		{
			field: "item_sales_id",
			headerName: "Item Sales ID",
			sortable: true,
			valueGetter: (data: DesignDataType) => data?.item_sales_id || "--",
		},
		{
			field: "item_id",
			headerName: "Item ID",
			sortable: true,
			valueGetter: (data: DesignDataType) => data?.item_id || "--",
		},
		{
			field: "item_number",
			headerName: "Item Num",
			sortable: true,
			valueGetter: (data: DesignDataType) => data?.item_number || "--",
		},
		{
			field: "color_code",
			headerName: "Color Code",
			sortable: true,
			valueGetter: (data: DesignDataType) => data?.color_code || "--",
		},
		{
			field: "pur_qr",
			headerName: "PUR QR",
			sortable: true,
			valueGetter: (data: DesignDataType) => data?.pur_qr || "--",
		},
		{
			field: "pvc_qr",
			headerName: "PVC QR",
			sortable: true,
			valueGetter: (data: DesignDataType) => data?.pvc_qr || "--",
		},
		{
			field: "masking_qr",
			headerName: "Masking QR",
			sortable: true,
			valueGetter: (data: DesignDataType) => data?.masking_qr || "--",
		},
		{
			field: "transaction_status",
			headerName: "Transaction Status",
			sortable: true,
			valueGetter: (data: DesignDataType) => data?.transaction_status || "--",
		},
	];

	const handleFieldSelect = (event: any, newFields: string[]) => {
		setSelectedFields(newFields);
		setSearchState(prev => {
			const newState = { ...prev };
			newFields.forEach(field => {
				if (!(field in newState)) {
					newState[field] = "";
					newState[field + "_match_type"] = "exact";
				}
			});
			return newState;
		});
	};

	const handleFieldDelete = (field: string) => {
		setSelectedFields(prevFields => prevFields.filter(f => f !== field));
		setSearchState(prev => {
			const newState = { ...prev };
			delete newState[field];
			delete newState[field + "_match_type"];
			return newState;
		});
	};

	const handleInputChange = (field: string, value: string) => {
		setSearchState(prev => ({ ...prev, [field]: value }));
	};

	const fetchData = async (query: string) => {
		try {
			setLoading(true);
			if (query) {
				const res = await getDesignData(query + `&page=${page}`);
				setData(res || []);
			} else {
				throw new Error("Please fill one or more fields to search for data");
			}
		} catch (err: any) {
			notify(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Slot name="main-toolbar">
				<Grid container alignItems="center" spacing={2}>
					<Grid item>
						{loading && <CircularProgress size={24} />}
						{!loading && <Typography variant="h6">Design Imaging Table</Typography>}
					</Grid>
					{(data?.data || []).length > 0 && (
						<>
							<Grid item xs />
							<Grid item>
								<Button
									variant="outlined"
									color="secondary"
									style={{ marginLeft: "auto" }}
									onClick={() => downloadDesignDatatoCsv(csvData)}
									startIcon={<Icon>download</Icon>}
									disabled={loading || data?.data.length === 0}
								>
									Download CSV
								</Button>
							</Grid>
						</>
					)}
				</Grid>
			</Slot>

			<Layout>
				<Content>
					<Container maxWidth={false}>
						<Paper
							onSubmit={e => {
								e.preventDefault();
								const params = removeEmpty(searchState);
								fetchData(formatSearchParams(params));
							}}
							component={"form"}
						>
							<Accordion defaultExpanded>
								<AccordionSummary
									aria-label="Expand"
									aria-controls="expand-search-by-selected-field"
									disableRipple
									expandIcon={<Icon>expand_more</Icon>}
								>
									<div
										onClick={event => event.stopPropagation()}
										style={{ width: "100%" }}
										onFocus={event => event.stopPropagation()}
									>
										<Autocomplete
											multiple
											id="search-by"
											options={searchByFields}
											value={selectedFields}
											onChange={handleFieldSelect}
											getOptionLabel={option => capitalize(option.replaceAll("_", " "))}
											renderTags={(value, getTagProps) =>
												value.map((option, index) => (
													<Chip
														key={option}
														label={capitalize(option.replaceAll("_", " "))}
														{...getTagProps({ index })}
														onDelete={() => handleFieldDelete(option)}
													/>
												))
											}
											renderOption={(option, { selected }) => (
												<React.Fragment>
													<Checkbox style={{ marginRight: 8 }} checked={selected} />
													{capitalize(option.replaceAll("_", " "))}
												</React.Fragment>
											)}
											renderInput={params => (
												<TextField
													{...params}
													label="Add or Remove Search Feilds"
													variant="outlined"
													size="small"
													fullWidth
													color="secondary"
												/>
											)}
											fullWidth
										/>
									</div>
								</AccordionSummary>
								<AccordionDetails style={{ padding: "0px 16px" }}>
									<div>
										{selectedFields.length > 0 && (
											<div>* Fill one or more fields to search through your data</div>
										)}
										<Box
											py={2}
											flexDirection={{ xs: "column", sm: "row" }}
											flexWrap={"wrap"}
											display={"flex"}
											alignItems={{ xs: "stretch", sm: "center" }}
											gridGap="1rem"
										>
											{selectedFields.map(field => (
												<TextField
													key={field}
													label={capitalize(field.replaceAll("_", " "))}
													variant="outlined"
													size="small"
													color="secondary"
													className="search-field"
													value={searchState[field] || ""}
													onChange={e => handleInputChange(field, e.target.value)}
													InputProps={{
														endAdornment: (
															<InputAdornment style={{ paddingBlockEnd: 0 }} position="end">
																<Select
																	defaultValue={"exact"}
																	color="secondary"
																	style={{
																		minHeight: 40,
																		maxHeight: 40,
																		backgroundColor: "rgb(245, 245, 245)",
																	}}
																	variant="filled"
																	value={searchState[field + "_match_type"]}
																	onChange={e =>
																		handleInputChange(
																			field + "_match_type",
																			e.target.value as string
																		)
																	}
																>
																	<MenuItem value="contains">contains</MenuItem>
																	<MenuItem value="exact">is exact</MenuItem>
																</Select>
															</InputAdornment>
														),
													}}
												/>
											))}
											{selectedFields.length > 0 && (
												<Button type="submit" variant="contained" color="secondary">
													Search
												</Button>
											)}
										</Box>
									</div>
								</AccordionDetails>
							</Accordion>
						</Paper>
					</Container>
					{data?.data && (
						<Container maxWidth={false} style={{ height: "calc(100% - 72px)" }}>
							<DataGrid
								rows={data?.data || []}
								columns={columns}
								windowHeight="100%"
								loading={loading}
								disableSelectionOnClick
							/>
							<Box mt={2} display="flex" justifyContent="center" sx={{ height: 56 }}>
								<Pagination
									count={data?.pagination?.total_pages}
									page={page}
									shape="rounded"
									onChange={(_, value) => setPage(value)}
								/>
							</Box>
						</Container>
					)}
				</Content>
			</Layout>
		</>
	);
};

export default DesignTable;
