import React, { useEffect, useState } from "react";
// import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@material-ui/core";
import { usePlaceReport } from "../../../../../core/providers/PlaceReportProvider";
import { useDateRange } from "../../../../../core/components/DateRangePicker";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import { visit3hd } from "../../../../../subapp/utils";
import DropDownWrapper from "../../../../../core/components/DropDownWrapper";
import MinMaxField from "./widgets/min-max-field";
import { RotateRight } from "@material-ui/icons";
import PlotLineInput from "./widgets/plot-line-input";
import { parseTimeStamp } from "../../../../../core/system/utils";

const Highcharts = require("highcharts/highstock");
// Initialize the module
NoDataToDisplay(Highcharts);

const TaktTimeChart = () => {
	const dateRange = useDateRange();
	const placeReport = usePlaceReport();

	const sortedLogs =
		placeReport?.logs?.sort(
			(a, b) => new Date(b.arrived_at).getTime() - new Date(a.arrived_at).getTime()
		) || [];

	const sortedLogsMinus1 = sortedLogs.slice(1);

	const times_adjusted =
		sortedLogsMinus1
			.map((el, index) => {
				const currentTime =
					new Date(sortedLogs[index]?.arrived_at).getTime() / 1000;
				const nextTime = new Date(el?.arrived_at).getTime() / 1000;
				return currentTime - nextTime;
			})
			.filter(el => !isNaN(el)) || [];

	const [data, setData] = useState(times_adjusted);

	// Automatically calculate max values for Y and X axes
	const calculateMaxYScale = (data: number[]) =>
		Math.round(Math.max(...data) + 50);
	const calculateMaxXScale = (logs: any[]) => logs.length - 1;

	const [maxYScale, setmaxYScale] = useState(calculateMaxYScale(data));
	const [maxXScale, setmaxXScale] = useState(
		calculateMaxXScale(sortedLogsMinus1)
	);
	const [yAxisRange, setYAxisRange] = useState<number[]>([0, maxYScale]);
	const [xAxisRange, setXAxisRange] = useState<number[]>([0, maxXScale]);
	const [plotLines, setplotLines] = useState<{ value: number; label: string }[]>(
		[]
	);

	const [averageTimeAdjusted, setAverageTimeAdjusted] = useState(
		data.reduce((sum, time) => sum + time, 0) / data.length
	);
	const removeDataPoint = (index: number) => {
		setData(prevData => {
			const newData = [...prevData];
			newData.splice(index, 1);

			// Recalculate max scales
			const newMaxYScale = calculateMaxYScale(newData);
			const newMaxXScale = calculateMaxXScale(
				sortedLogsMinus1.slice(0, newData.length)
			);

			// Update ranges
			setmaxYScale(newMaxYScale);
			setmaxXScale(newMaxXScale);
			setYAxisRange([0, newMaxYScale]);
			setXAxisRange([0, newMaxXScale]);

			// Recalculate average time adjusted
			const newAverageTime =
				newData.length > 0
					? newData.reduce((sum: number, time: number) => sum + time, 0) /
					  newData.length
					: 0;

			setAverageTimeAdjusted(newAverageTime);

			// Update chart options dynamically
			setChartOptions(prev => ({
				...prev,
				yAxis: {
					...prev.yAxis,
					min: 0,
					max: newMaxYScale,
				},
				xAxis: {
					...prev.xAxis,
					min: 0,
					max: newMaxXScale,
					categories: prev.xAxis.categories?.filter((_, i: number) => i !== index),
				},
				series: [
					{
						...(prev?.series || [])[0],
						data: newData,
					},
				],
			}));

			return newData;
		});
	};
	const resetData = () => {
		// Reset the data to the original `times_adjusted` values
		setData(times_adjusted);

		// Recalculate the max Y and X scales based on the original data
		const newMaxYScale = calculateMaxYScale(times_adjusted);
		const newMaxXScale = calculateMaxXScale(sortedLogsMinus1);

		// Update max scales and axis ranges
		setmaxYScale(newMaxYScale);
		setmaxXScale(newMaxXScale);
		setYAxisRange([0, newMaxYScale]);
		setXAxisRange([0, newMaxXScale]);

		// Recalculate and set the average time adjusted
		const newAverageTime =
			times_adjusted.reduce((sum, time) => sum + time, 0) / times_adjusted.length;
		setAverageTimeAdjusted(newAverageTime);
		setplotLines([]);

		// Reset the chart options to reflect the original data
		setChartOptions(prev => ({
			...prev,
			yAxis: {
				...prev.yAxis,
				min: 0,
				max: newMaxYScale,
				plotLines: [
					{
						...prev.yAxis.plotLines[0],
						value: newAverageTime,
					},
				],
			},
			xAxis: {
				...prev.xAxis,
				min: 0,
				max: newMaxXScale,
				categories: sortedLogsMinus1.map(
					el => el?.product?.part_number || el?.license_plate?.part_number
				),
			},
			series: [
				{
					...(prev?.series || [])[0],
					data: times_adjusted,
					threshold: newAverageTime,
					zones: [
						{
							value: newAverageTime,
							color: "green", // Dark green for below average
							fillColor: "#0064002d",
							marker: {
								fillColor: "green",
								lineColor: "green",
							},
						},
						{
							color: "red", // Dark red for above average
							fillColor: "#fc3f3f26",
							marker: {
								fillColor: "red",
								lineColor: "red",
							},
						},
					],
				},
			],
		}));
	};

	const options = {
		chart: {
			type: "areaspline", // Using areaspline for shading
			height: 550,
			backgroundColor: "transparent",
			style: {
				fontFamily: "Arial, sans-serif",
			},
			zooming: {
				type: "xy",
			},
		},
		noData: {
			style: {
				fontWeight: "bold",
				fontSize: "24px",
			},
		},
		title: {
			style: { display: "none" },
		},
		xAxis: {
			categories: sortedLogsMinus1?.map(
				el => el?.product?.part_number || el?.license_plate?.part_number
			),
			endOnTick: true,
			// showLastLabel: false,
			min: xAxisRange[0],
			max: xAxisRange[1],
			title: {
				text: `Last ${sortedLogsMinus1?.length} Unit's Part Numbers`,
				margin: 10,
			},
			// scrollbar: {
			// 	enabled: screenWidth <= breakpoints.md ? true : false,
			// },
		},
		yAxis: {
			title: {
				text: "Time (seconds)",
			},
			min: yAxisRange[0],
			max: yAxisRange[1],
			gridLineWidth: 0.5,
			gridLineColor: "lightgray",
			plotLines: [
				{
					value: averageTimeAdjusted,
					color: "grey",
					dashStyle: "dash",
					width: 4,
					zIndex: 5,
					label: {
						text: "Average Time",
					},
				},
			],
		},
		tooltip: {
			shared: true,
			crosshairs: true,
			valueDecimals: 4,
			formatter: function (
				this: Highcharts.TooltipFormatterContextObject
			): string {
				// Ensure the tooltip points are valid
				if (!this.points || !sortedLogsMinus1) return "";

				// Retrieve the current point's index
				const dateIndex = this.points[0]?.point?.index as number;

				// Retrieve and format the date using Moment.js
				const rawDate = sortedLogsMinus1[dateIndex]?.arrived_at;
				if (!rawDate) return "";

				const formattedDate = parseTimeStamp(rawDate).format(
					"MMM. Do, YYYY - hh:mm:ss A"
				);

				// Combine the formatted date with the y-axis value
				return `Part #: ${
					this.points[0].x
				} arrived at <b>${formattedDate}</b><br/>Time Difference: ${this.points[0].y.toLocaleString()} seconds`;
			},
		},
		legend: {
			enabled: false, // Hiding the Highcharts legend as custom legend is used
		},
		series:
			times_adjusted.length > 0
				? [
						{
							name: "Process Time",
							data: data,
							marker: {
								enabled: true,
								radius: 5,
								symbol: "circle",
							},
							zoneAxis: "y",
							allowPointSelect: true,
							point: {
								events: {
									select: function (event: any) {
										setOpen(true);
										setEvent(event);
									},
								},
							},
							threshold: averageTimeAdjusted,
							zones: [
								{
									value: averageTimeAdjusted,
									color: "green", // Dark green for below average
									fillColor: "#0064002d",
									marker: {
										fillColor: "green",
										lineColor: "green",
									},
								},
								{
									color: "red", // Dark red for above average
									fillColor: "#fc3f3f26",
									marker: {
										fillColor: "red",
										lineColor: "red",
									},
								},
							],
						},
				  ]
				: null,
		plotOptions: {
			areaspline: {
				fillOpacity: 0.3,
				lineWidth: 3,
				states: {
					hover: {
						lineWidth: 4,
					},
				},
				marker: {
					enabled: true,
					lineWidth: 2,
				},
			},
		},
	};

	const [event, setEvent] = useState<any>();
	const [chartOptions, setChartOptions] = useState(options);

	const [open, setOpen] = useState(false);
	const [option, setOption] = useState<"remove" | "view">("remove");

	// update zones and average treshold
	useEffect(() => {
		setChartOptions(prev => ({
			...prev,
			series: (prev?.series || [])?.map((series: any) => ({
				...series,
				threshold: averageTimeAdjusted,
				zones: [
					{
						value: averageTimeAdjusted,
						color: "green", // Dark green for below average
						fillColor: "#0064002d",
						marker: {
							fillColor: "green",
							lineColor: "green",
						},
					},
					{
						color: "red", // Dark red for above average
						fillColor: "#fc3f3f26",
						marker: {
							fillColor: "red",
							lineColor: "red",
						},
					},
				],
			})),
			yAxis: {
				...prev.yAxis,
				plotLines: [
					{
						...prev.yAxis.plotLines[0],
						value: averageTimeAdjusted,
					},
				],
			},
		}));
	}, [averageTimeAdjusted]);

	return (
		<Card style={{ background: "white", padding: "1rem" }}>
			<div
				style={{
					display: "flex",
					gap: "0.5rem",
					marginBottom: "1rem",
					justifyContent: "space-between",
					flexWrap: "wrap",
				}}
			>
				<Typography variant="h5">
					{dateRange?.from
						? `${placeReport?.name} Takt Time (${parseTimeStamp(
								dateRange?.from
						  ).format("MMM DD")} - ${parseTimeStamp(dateRange?.to).format(
								"MMM DD"
						  )})`
						: `${placeReport?.name} Takt Time`}
				</Typography>
				{sortedLogsMinus1.length > 0 && (
					<div
						style={{
							display: "flex",
							gap: "0.5rem",
							alignItems: "center",
							marginLeft: "auto",
						}}
					>
						<Button
							size="small"
							variant="outlined"
							color="secondary"
							onClick={() => resetData()}
						>
							<RotateRight />
						</Button>
						<DropDownWrapper
							className="graph-dropdown"
							action={
								<Button size="small" variant="outlined" color="secondary">
									Customize Graph
								</Button>
							}
						>
							<form
								onSubmit={e => {
									e.preventDefault();
									const extraPlotLines = plotLines.map(el => ({
										value: parseFloat(el.value.toString()),
										color: "grey",
										dashStyle: "dash",
										width: 4,
										zIndex: 5,
										label: {
											text: el.label,
										},
									}));
									setChartOptions(prev => ({
										...prev,
										yAxis: {
											...prev.yAxis,
											min: yAxisRange[0],
											max: yAxisRange[1],
											plotLines: [
												{
													...prev.yAxis.plotLines[0],
													value: averageTimeAdjusted,
												},
												...extraPlotLines,
											],
										},
										xAxis: {
											...prev.xAxis,
											min: xAxisRange[0],
											max: xAxisRange[1],
										},
									}));
								}}
							>
								<PlotLineInput
									plotLines={plotLines}
									setplotLines={setplotLines}
									fixedMax={maxYScale}
								/>
								<MinMaxField
									range={yAxisRange}
									setRange={setYAxisRange}
									step={20}
									fixedMin={0}
									label="Y-Axis Min and Max Scale"
									fixedMax={maxYScale}
								/>
								<MinMaxField
									range={xAxisRange}
									setRange={setXAxisRange}
									step={1}
									label="X-Axis Min and Max Scale"
									fixedMin={0}
									fixedMax={maxXScale}
								/>

								<div>
									<Button
										onClick={() => {}}
										variant="contained"
										fullWidth
										type="submit"
										color="secondary"
									>
										Continue
									</Button>
								</div>
							</form>
						</DropDownWrapper>
					</div>
				)}
			</div>
			<HighchartsReact
				highcharts={Highcharts}
				options={chartOptions}
				allowChartUpdate={true}
			/>
			{times_adjusted.length > 0 && (
				<div style={{ textAlign: "center", marginTop: "20px" }}>
					<span
						style={{
							display: "inline-flex",
							alignItems: "center",
							marginRight: "30px",
						}}
					>
						<span
							style={{
								backgroundColor: "green",
								display: "inline-block",
								width: "20px",
								height: "20px",
								marginRight: "5px",
								borderRadius: "3px",
							}}
						></span>
						Fast (below average)
					</span>
					<span style={{ display: "inline-flex", alignItems: "center" }}>
						<span
							style={{
								backgroundColor: "red",
								display: "inline-block",
								width: "20px",
								height: "20px",
								marginRight: "5px",
								borderRadius: "3px",
							}}
						></span>
						Slow (above average)
					</span>
				</div>
			)}

			<Dialog open={open} fullWidth maxWidth="xs" onClose={() => setOpen(false)}>
				<DialogTitle>Choose action to perform:</DialogTitle>
				<DialogContent>
					<FormControl>
						<RadioGroup value={option} onChange={(ev, val: any) => setOption(val)}>
							<FormControlLabel
								value={"remove"}
								label={"Hide Datapoint"}
								control={<Radio />}
							/>
							<FormControlLabel
								value={"view"}
								label={"View Datapoint's Licenseplate"}
								control={<Radio />}
							/>
						</RadioGroup>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button color="secondary" onClick={() => setOpen(false)}>
						Cancel
					</Button>
					<Button
						color="secondary"
						variant="contained"
						onClick={() => {
							if (option === "remove") {
								removeDataPoint(event?.target?.index);
							} else {
								visit3hd(
									`/${
										sortedLogsMinus1?.find((_, i) => i === event.target.index)
											?.license_plate.lp_id
									}`
								);
							}
							setOpen(false);
						}}
					>
						Continue
					</Button>
				</DialogActions>
			</Dialog>
		</Card>
	);
};

export default TaktTimeChart;
