import moment from "moment";
import apiClient from "../ordertrack-client";
import dataToCsv from "../utils/data-to-csv";
import { DesignApiResponseType } from "./types";

export async function getDesignData(
	urlParams?: string,
	page?: number
): Promise<DesignApiResponseType> {
	const res = await apiClient.get(`/reports/erp?${urlParams || ""}`);
	return res.data;
}

export const downloadDesignDatatoCsv = async (data: any) => {
	dataToCsv(data, `design-table-${moment().format("DD MMM, YYYY [at] hh:mma")}`);
};
