import React, { useState } from "react";
import { DateTagProps } from "./types";
import moment from "moment";
import { parseTimeStamp } from "../../system/utils";

const DateTag: React.FC<DateTagProps> = props => {
	const { date, initialMode = "date-time", hideModeSwitch = false } = props;

	const [currMode, setCurrMode] = useState(initialMode);

	if (!date) return <>{props.children}</>;

	const formattedValue = (() => {
		switch (currMode) {
			case "since-today":
				return moment.duration(parseTimeStamp(date).diff(moment())).humanize(true);
			case "date-time":
			default:
				return parseTimeStamp(date).format("DD MMM, YYYY [at] hh:mma");
		}
	})();

	if (hideModeSwitch) return <>{formattedValue}</>;

	return (
		<span
			onClick={() =>
				setCurrMode(m => (m === "date-time" ? "since-today" : "date-time"))
			}
			style={{ cursor: "pointer" }}
		>
			{currMode === "date-time" ? <>&#128197;</> : <>&#128337;</>} {formattedValue}
		</span>
	);
};

export default DateTag;
