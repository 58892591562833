import React, {
	useRef,
	useState,
	useLayoutEffect,
	useCallback,
	useEffect,
	useMemo,
} from "react";
import {
	getAllOrders,
	generateActivitySheets,
	ProductionOrder,
	ITemplateFields,
} from "../../core/system/production-orders";
import useNotify from "../../core/hooks/use-notify";
import { Link as RouterLink } from "react-router-dom";
import Layout, { Header, Content, Slot } from "../../core/components/Layout";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Pagination, PaginationItem } from "@material-ui/lab";
import DateTag from "../../core/components/DateTag";
import DataGrid, { ColumnDef, RowData } from "../../core/components/DataGrid";
import EmbeddedContentDialog, {
	EmbeddedContentDialogApi,
} from "../../core/components/EmbeddedContentDialog";
import ViewPermissionsDialog, {
	ViewPermissionsDialogApi,
} from "../ViewPermissionsDialog";
import OptionsDialog, {
	OptionsDialogApi,
} from "../../core/components/OptionsDialog";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DropDownWrapper from "../../core/components/DropDownWrapper";
import OrderTemplateDialog, {
	OrderTemplateDialogApi,
} from "../OrderTemplateDialog";
import { visit3hd } from "../../subapp/utils";
import EditProductionOrderDialog, {
	EditProductionOrderDialogApi,
} from "../EditProductionOrder";
import { parseTimeStamp } from "../../core/system/utils";
import { AddMemoDialog } from "../../core/components/MainToolbar/MainToolbar";

const ViewProductionOrders: React.FC = () => {
	const activitySheetsDialog = useRef<EmbeddedContentDialogApi>(null);
	const viewPermissionDialog = useRef<ViewPermissionsDialogApi>(null);
	const viewTemplateDialog = useRef<OrderTemplateDialogApi>(null);
	const viewEditDialog = useRef<EditProductionOrderDialogApi>(null);
	const [orders, setOrders] = useState<{ [key: number]: ProductionOrder[] }>({});
	const [org_template, set_org_template] = useState<ITemplateFields>({});
	const [loading, setLoading] = useState(false);
	const notify = useNotify();
	const [selection, setSelection] = useState<ProductionOrder[]>([]);
	const [tablePage, setTablePage] = useState(1);
	const [availablePages, setAvailablePages] = useState(1);
	const [loadingOrders, setLoadingOrders] = useState(false);
	const [refetch, setRefetch] = useState(false);
	const [loadedPages, setLoadedPages] = useState<Set<number>>(new Set());

	const columns: ColumnDef[] = [
		{
			field: "created_at",
			headerName: "CREATED",
			sortable: true,
			valueGetter: (data: ProductionOrder) =>
				parseTimeStamp(data.created_at).valueOf(),
			renderCell: (data: ProductionOrder) => (
				<DateTag
					date={data.created_at}
					dateInputType="utc"
					initialMode="since-today"
				/>
			),
		},
		{
			field: "part_number",
			headerName: "PART #",
			sortable: true,
			padding: "checkbox",
			width: 300,
			valueGetter: (data: ProductionOrder) => data.product.part_number,
			renderCell: (data: ProductionOrder) => (
				<Link
					onClick={() => visit3hd(`/${data.docid}`)}
					style={{
						maxWidth: "200px",
						wordWrap: "break-word",
						display: "block",
						fontSize: "1rem",
						whiteSpace: "pre-wrap",
					}}
					color="textSecondary"
				>
					{data.product.part_number ||
						`${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/...${data.docid?.slice(
							-5
						)}`}
				</Link>
			),
		},
		{
			field: "external_docid",
			headerName: "WORK ORDER #",
			sortable: true,
			valueGetter: (data: ProductionOrder) => data.external_docid || "--",
		},
		{
			field: "docid",
			headerName: "QR code",
			renderCell: (data: ProductionOrder) => (
				<Link
					color="textPrimary"
					onClick={() => visit3hd(`/${data.docid}`)}
					target="_BLANK"
				>
					<Typography>
						<small>{process.env.REACT_APP_QR_BASENAME || "3hd.us"}/...</small>
						{data.docid?.slice(-5)}
					</Typography>
				</Link>
			),
		},
		{
			field: "requested_qty",
			headerName: "QTY",
			sortable: true,
			valueGetter: (data: ProductionOrder) => data.requested_qty,
		},
		{
			field: "description",
			headerName: "DESCRIPTION",
			sortable: true,
			valueGetter: (data: ProductionOrder) => data.product.description || "--",
			renderCell: (data: ProductionOrder) => (
				<span
					style={{
						maxWidth: "450px",
						wordWrap: "break-word",
						display: "block",
						fontSize: "1rem",
						whiteSpace: "pre-wrap",
					}}
				>
					{data.product.description || "--"}
				</span>
			),
		},
		{
			field: "actions",
			headerName: "ACTIONS",
			sortable: true,
			valueGetter: (data: ProductionOrder) => data.product.description || "--",
			renderCell: (data: ProductionOrder) => (
				<DropDownWrapper
					className="more-actions"
					action={
						<IconButton className="more-action-btn" aria-label="actions">
							<MoreVertIcon />
						</IconButton>
					}
				>
					<Button
						variant="text"
						type="button"
						onClick={() => viewPermissionDialog.current?.openDialog(data)}
					>
						Edit Permission
					</Button>
					<Button
						variant="text"
						type="button"
						onClick={() => viewEditDialog.current?.openDialog(data)}
					>
						Edit Production Order
					</Button>
					{org_template && (
						<Button
							variant="text"
							type="button"
							onClick={() =>
								viewTemplateDialog.current?.openDialog(data, org_template)
							}
						>
							Edit Order Details
						</Button>
					)}
				</DropDownWrapper>
			),
		},
	];

	const handleSelection = (selection: RowData[]) => {
		setSelection(selection as ProductionOrder[]);
	};

	useLayoutEffect(() => {
		let active = true;
		// setTablePage(1);
		(async () => {
			try {
				setLoading(true);
				const response = await getAllOrders(tablePage, 50);
				if (!active) return;
				set_org_template(response.data.org_template);
				setOrders(prev => ({
					...prev,
					[tablePage]: response.data.orders
						.filter(o => o.status !== "DELETED")
						.sort(
							(a, b) =>
								parseTimeStamp(b.created_at).valueOf() -
								parseTimeStamp(a.created_at).valueOf()
						),
				}));
				setAvailablePages(response.pagination.total_pages);
				setLoading(false);
				setRefetch(false);
			} catch (err) {
				if (!active) return;
				notify(err as Error);
				setLoading(false);
			}
		})();

		return () => {
			active = false;
			setRefetch(false);
		};
		// eslint-disable-next-line
	}, [notify, refetch]);

	const fetchNextOrders = useCallback(
		async (page: number) => {
			try {
				setLoadingOrders(true);
				const response = await getAllOrders(page, 50); // Replace with your actual API call
				set_org_template(response.data.org_template);
				const nextOrders = response.data.orders
					.filter(o => o.status !== "DELETED")
					.sort(
						(a, b) =>
							parseTimeStamp(b.created_at).valueOf() -
							parseTimeStamp(a.created_at).valueOf()
					);
				setOrders(prevOrders => ({
					...prevOrders,
					[page]: nextOrders,
				}));
				setLoadedPages(prevPages => new Set(prevPages).add(page));
			} catch (err) {
				notify(err as Error); // Replace with your actual error handling
			} finally {
				setLoadingOrders(false);
			}
		},
		[notify]
	);

	// in case you want to preload all previous page before the actual selected page - it's a bit slower than the solution down
	// useEffect(() => {
	//   const fetchMissingPages = async () => {
	//     const pagesToFetch = [];
	//     for (let page = 1; page <= tablePage; page++) {
	//       if (!loadedPages.has(page)) {
	//         pagesToFetch.push(page);
	//       }
	//     }

	//     // Fetch all missing pages in parallel
	//     await Promise.all(pagesToFetch.map(page => fetchNextOrders(page)));
	//   };

	//   fetchMissingPages();
	// }, [tablePage, fetchNextOrders, loadedPages]);

	useEffect(() => {
		const fetchPage = async (page: number) => {
			if (!loadedPages.has(page)) {
				await fetchNextOrders(page);
			}
		};

		fetchPage(tablePage);
	}, [tablePage, fetchNextOrders, loadedPages]);

	const currentOrders = useMemo(() => {
		return orders[tablePage] || [];
	}, [orders, tablePage]);

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setTablePage(value);
	};

	const showPagination = useMemo(() => {
		// Check if there are any orders loaded and if multiple pages are available
		return Object.keys(orders).length > 0 && availablePages > 1;
	}, [orders, availablePages]);

	const handleButtonClick = () => {
		optionsDialog.current?.openDialog();
	};
	const optionsDialog = useRef<OptionsDialogApi>(null);
	const handleSubmit = (option: string) => {
		activitySheetsDialog.current?.openDialog(() =>
			generateActivitySheets(selection, option)
		);
	};
	return (
		<>
			<Slot name="main-toolbar">
				<Grid container alignItems="center" spacing={2}>
					<Grid item>
						{(loading || loadingOrders) && <CircularProgress size={24} />}
						{!loading && !loadingOrders && (
							<Typography variant="h6">Orders</Typography>
						)}
					</Grid>
					<Grid item xs />

					<Grid item style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
						<AddMemoDialog />
						<Link component={RouterLink} underline="none" to="/orders/new">
							<Button disabled={loading} color="secondary" variant="contained">
								<Icon>add</Icon> Add Order
							</Button>
						</Link>
					</Grid>
				</Grid>
			</Slot>

			<Layout>
				<Header>
					<Container maxWidth={false}>
						<Paper>
							<Box px={2} py={1}>
								<Grid container alignItems="center" spacing={2}>
									<Grid item>
										<Hidden xsDown>
											<Button
												color="secondary"
												startIcon={<Icon>print</Icon>}
												onClick={handleButtonClick}
												disabled={loading || selection.length === 0}
											>
												Get labels
											</Button>
										</Hidden>
										<Hidden smUp>
											<IconButton
												edge="start"
												color="secondary"
												onClick={handleButtonClick}
												disabled={loading || selection.length === 0}
											>
												<Icon>print</Icon>
											</IconButton>
										</Hidden>
									</Grid>
									<Grid item>
										<Typography>
											<b>{selection.length || "No"}</b> rows selected.
										</Typography>
									</Grid>
								</Grid>
							</Box>
						</Paper>
					</Container>
				</Header>
				<Content>
					<Container maxWidth={false} style={{ height: "calc(100% - 72px)" }}>
						<DataGrid
							rows={currentOrders}
							columns={columns}
							windowHeight="100%"
							checkboxSelection
							loading={loading || loadingOrders}
							disableSelectionOnClick
							onSelectionChange={handleSelection}
						/>
					</Container>
					{showPagination && (
						<Box mt={2} display="flex" justifyContent="center" sx={{ height: 56 }}>
							<Pagination
								count={availablePages}
								page={tablePage}
								onChange={handleChange}
								hidePrevButton
								hideNextButton
								shape="rounded"
								renderItem={item => (
									<PaginationItem {...item} disabled={loadingOrders} />
								)}
							/>
						</Box>
					)}
				</Content>
			</Layout>

			<OptionsDialog
				ref={optionsDialog}
				title="Choose option:"
				onSubmit={handleSubmit}
				options={[
					{ label: "Made auto", value: "made-auto" },
					{ label: "Made many", value: "made-many" },
					{ label: "Made with notes", value: "made-notes" },
					{ label: "Stack order", value: "stack-order" },
					{ label: "Production order only", value: "production-order" },
				]}
			/>

			<EmbeddedContentDialog
				ref={activitySheetsDialog}
				title="Production orders"
			/>
			<ViewPermissionsDialog setRefetch={setRefetch} ref={viewPermissionDialog} />
			<EditProductionOrderDialog setRefetch={setRefetch} ref={viewEditDialog} />
			<OrderTemplateDialog ref={viewTemplateDialog} />
		</>
	);
};

export default ViewProductionOrders;
