import React, { useState, useMemo, useCallback } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import TrainingEditor from "./TrainingEditor";
import QrCodeScanner from "../core/components/QrCodeScanner/QrCodeScanner.js";
import TrainingLabelsGeneratorDialog from "./TrainingLabelsGeneratorDialog";
import useNotify from "../core/hooks/use-notify";
import { useAuthentication } from "../core/providers/AuthenticationProvider";
import parseMomentTrackId, {
	generateIds,
} from "../core/system/utils/parse-momenttrack-id";
import { lookupTraining, TrainingDocument } from "../core/system/litmon";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import TrainingList from "./TrainingList";
import { Slot } from "../core/components/Layout";
import { AddMemoDialog } from "../core/components/MainToolbar/MainToolbar";

const LearningInTheMoment: React.FC = () => {
	const notify = useNotify();
	const [currUser] = useAuthentication();
	const [loading, setLoading] = useState(false);
	const [docid, setDocid] = useState<string | null>(null);
	const [trainingDoc, setTrainingDoc] = useState<TrainingDocument>();
	const [editorOpen, setEditorOpen] = useState(false);
	const [editorType, seteditorType] = useState<"edit" | "post">("post");
	const [showLabelsDialog, setShowLabelsDialog] = useState(false);

	const currTraining = useMemo(() => {
		if (!trainingDoc || trainingDoc.organization_id !== currUser?.organization_id)
			return;

		return trainingDoc;
	}, [trainingDoc, currUser]);

	const handleQrScanResult = useCallback(
		async (result: string) => {
			try {
				const parsed = parseMomentTrackId(result);
				if (parsed.type !== "document")
					throw new Error('Invalid QR. Please scan a valid "document" QR code.');

				setDocid(parsed.id);
				setTrainingDoc(undefined);
				setLoading(true);
				const trainingDoc = await lookupTraining(parsed.id).catch(() => {
					seteditorType("post");
					setEditorOpen(true);
					return undefined;
				});

				if (trainingDoc) {
					seteditorType("edit");
					setTrainingDoc(
						trainingDoc?.pick_type === "TRAINING"
							? {
									...trainingDoc,
									instructions: JSON.parse(
										JSON.stringify(trainingDoc?.instructions || [])
									),
							  }
							: undefined
					);
				}
			} catch (err) {
				notify(err as Error);
			} finally {
				setLoading(false);
			}
		},
		[notify]
	);

	const openTrainingModal = (
		trainingData: TrainingDocument,
		type: "edit" | "post"
	) => {
		setDocid(trainingData.doc_id);
		setTrainingDoc({
			...trainingData,
			instructions: JSON.parse(JSON.stringify(trainingData?.instructions || [])),
		});
		setEditorOpen(true);
		seteditorType(type);
	};

	const [refetchList, setrefetchList] = useState(0);

	return (
		<Box>
			<Slot name="main-toolbar">
				<Grid
					container
					alignItems="center"
					justifyContent="space-between"
					spacing={1}
				>
					<Grid item xs></Grid>
					<Grid item>
						<AddMemoDialog />
					</Grid>
				</Grid>
			</Slot>

			<Container style={{ padding: "2rem 1rem" }} maxWidth="sm">
				<Box mb={5} textAlign="center">
					<Typography variant="h4" gutterBottom>
						Learning in the moment
					</Typography>
					<Typography variant="body2">
						Create training: Start by clicking on create video or scanning a training
						document QR code to link the training with.
					</Typography>
				</Box>

				{loading && (
					<Box textAlign="center" py={2}>
						<CircularProgress size={24} />
					</Box>
				)}

				{!!trainingDoc && (
					<Box mb={5}>
						{!currTraining && (
							<Typography color="error">
								This QR code has already been linked with another training. Please scan
								a different label.
							</Typography>
						)}
						{!!currTraining && (
							<Box>
								<Card>
									<CardHeader
										avatar={
											<Avatar variant="square">
												<Icon>school</Icon>
											</Avatar>
										}
										title={currTraining.title}
										subheader={
											<Chip
												icon={<Icon fontSize="small">person</Icon>}
												label={`${trainingDoc.user.first_name} ${trainingDoc.user.last_name}`}
												size="small"
											/>
										}
										titleTypographyProps={{ variant: "h6" }}
									/>
									<CardContent>
										<Typography variant="body2">
											This QR code <code>`3hd.us/...{docid?.slice(-5)}`</code> has already
											been linked with a training. You can choose to edit the training or
											to scan a different label.
										</Typography>
									</CardContent>
									<Divider light />
									<CardActions>
										<Button
											onClick={() => {
												seteditorType("edit");
												setEditorOpen(true);
											}}
											color="secondary"
											startIcon={<Icon>edit</Icon>}
										>
											Make changes
										</Button>
									</CardActions>
								</Card>
							</Box>
						)}
					</Box>
				)}
				<Grid
					container
					direction="column"
					spacing={3}
					alignItems="center"
					justifyContent="center"
				>
					<Grid item style={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
						<Button
							variant="contained"
							className="w-sm-full"
							startIcon={<VideoCallIcon />}
							color="secondary"
							onClick={() => {
								handleQrScanResult(generateIds("document", 1)[0]);
							}}
						>
							Create Video
						</Button>
						<QrCodeScanner
							onResult={handleQrScanResult}
							label={!trainingDoc ? "Scan label" : "Scan different label"}
							disabled={loading}
							ButtonProps={{
								variant: "outlined",
								color: "secondary",
								className: "w-sm-full",
							}}
						/>
					</Grid>
					<Grid item>
						<Link
							color="secondary"
							href="#"
							onClick={() => setShowLabelsDialog(true)}
						>
							Get empty labels
						</Link>
					</Grid>
				</Grid>

				<Dialog open={editorOpen} fullScreen>
					<DialogTitle>Learning in the moment</DialogTitle>
					<Divider />
					<DialogContent>
						<Box pt={2} pb={10}>
							<Container>
								{!!docid && (
									<TrainingEditor
										docid={docid}
										type={editorType}
										trainingDocument={trainingDoc}
										onCancel={() => {
											setEditorOpen(false);
											setrefetchList(prev => prev + 1);
											setTrainingDoc(undefined);
										}}
									/>
								)}
							</Container>
						</Box>
					</DialogContent>
				</Dialog>

				<TrainingLabelsGeneratorDialog
					open={showLabelsDialog}
					onClose={(ev, reason) =>
						reason !== "backdropClick" && setShowLabelsDialog(false)
					}
					disableEscapeKeyDown
					maxWidth="sm"
					fullWidth
				/>
			</Container>

			<TrainingList
				setrefetchList={setrefetchList}
				refetchList={refetchList}
				openTrainingModal={openTrainingModal}
			/>
		</Box>
	);
};

export default LearningInTheMoment;
